.car-washing-booking {


    a {
        text-decoration: none;
    }

    .info-text {
        text-align: left;
        width: 100%;
    }

   button{
    width: 100%;
    background: #ffa707;
    border: none;
    padding: 5px;
   }

    header,
    form {
        padding: 10px 1%;
    }

    .form-group {
        margin-bottom: 20px;
    }

    h2.heading {
        font-size: 20px;
        font-weight: 300;
        text-align: left;
        border-bottom: 1px solid #ddd;
        padding-bottom: 3px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .controls {
        text-align: left;
        position: relative;

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="time"],
        input[type="date"],
        input[type="tel"],
        textarea,
        button,
        select {
            padding: 5px;
            font-size: 14px;
            border: 1px solid #ddd;
            border-radius: 5px;
            width: 100%;
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 300;



        }

        .fa-sort {
            position: absolute;
            right: 10px;
            top: 17px;
            color: #999;

        }


    }





}