@media screen and (max-width:768px) {
    .washing-wale-seller .quick-link-area ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block !important;

    }

    .washing-wale-seller .quick-link-area ul li a {

        margin: 20px;
    }

    .profile-info {
        font-size: 12px;
    }

    .car-washing-wale-navbar .dropdown-manu {
        display: none !important;
    }

    .slide img {
        height: 302px;
        width: 100% !important;
    }

    .deshbord {
        display: none !important;
    }

    .washing-nav .navbar-nav a {
        margin: 5px 10px !important;
        color: #000;
        font-weight: 500;
        border-bottom: 1px solid #ed8600;
        padding: 10px 0;
    }

    .mobile-menu-wahingwale {
        display: block !important;
    }

    @media screen and (max-width: 400px) {
        .login-model .modal-dialog {
            width: 100% !important;
        }

        .washingwale {
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;
            padding: 7rem 5px 0px !important;
        }
    }

    @media screen and (max-width: 600px) {

        .Washing-wale-profile {
            .nav-pills {
                display: flex;
                justify-content: space-around;
                font-size: 12px;
                flex-direction: row !important;
            }

            #orderDetails {
                th {
                    font-size: 12px;
                }

                td {
                    font-size: 12px;
                }
            }

            .nav-item {
                width: 33%;

                a {
                    padding: 5px 0;
                }
            }
        }


        .washing-wale-seller .new-form {
            margin-top: 40px;
        }

        .washing-wale-seller .pt-165 {
            padding: 10px 0 !important;
        }

        .main {
            width: 100% !important;
        }

        .main2 {
            display: block !important;
        }
        .filter{
            margin: 10px 0;
            a{
                padding: 5px 20px;
                border: 1px solid #ed8600;
                text-decoration: none;
                border-radius: 5px;
            }
        }

        .heading {
            width: 100% !important;
        }

        .heading h1 {
            font-size: 40px !important;
            font-weight: 900;
            color: #ed8600;
        }

        .slide img {
            height: 200px !important;
            padding: 0px !important;
        }

        .product {
            margin: 20px 0;
        }
        .title-slider h1 {
            font-size: 30px;
        }
        .banner-block {
            display: none;
        }

        .car-washing-register {
            .content {
                display: none;
            }

            .SignUp-box {
                padding: 5em 0.5em 0em 0.5em !important;
            }
        }

        .slide2 {
            width: 100% !important;
        }

        .washingwale {
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;
            padding: 5rem 5px 0px !important;
        }

        .login-model .modal-dialog {
            width: 100% !important;
        }

        .washing-car .car-details-area .kye-features ul {
            margin: 0;
            padding: 0;
            list-style: none;
            columns: 2 !important;

        }

        .booking-model .modal-dialog {
            width: 100% !important;
            margin: 0px;
        }

        .washing-section-title1 span h2 {
            font-size: 20px !important;
            color: #ed8600;
        }

        .listing-img img {
            height: 100% !important;
        }

        .carlisting-washingwale {
            padding: 1rem 0 !important;
        }

        .bottom-navbar-icon {
            display: flex !important;
        }

        .top-menu .nav-links li:hover .mega-box {
            display: block;
            padding: 0px !important;
        }

        .top-none {
            display: none !important;

        }

        .carwashing-noida-servies li {
            font-size: 12px;
        }

        .Filter {
            display: block;
        }

        .d-md-flex {
            display: flex;
        }

        .item-card9-img {
            width: 40% !important;
            position: relative;
        }

        .list-data {
            font-size: 12px;
        }

        .item-card9 {
            padding: 2px 5px;

            h5 {
                font-size: 16px;
            }

            p {
                font-size: 12px;
            }
        }

        .item-card9-cost {
            p {
                font-size: 12px !important;
            }
        }

        .washingwale .explore-text {
            /* color: #2f2f2f; */
            padding: 10px;
            background: #fff;
            border-radius: 70px;
            display: inline-block;
            margin: 0 0 15px;
            font-size: 12px;
        }

        .carlisting-washingwale .mySwiper .washingwale-listing .btn-order {
            color: #ed8600;
            position: relative;
            border-radius: 5px;
            font-weight: 600;
            font-size: 12px !important;
            color: #0e0d0d;
            text-align: center;
            padding: 10px 0 !important;
            border-radius: 10px;
        }

        .top-bar-right .custom {

            padding: 0px 5px !important;
        }

        .washingwale h1 {
            font-weight: 900;
            font-size: 25px !important;
            color: #fe9307 !important;
        }

        .top-bar-right .custom {
            float: none !important;
            justify-content: space-around;
        }

        .washingwale h1 span {
            color: #000 !important;
            font-size: 29px !important;
        }

        .banner-imgs {
            display: none;
        }

        .input-group .btn {
            position: relative;
            z-index: 0;
        }

        .top-menu .nav-links .mobile-item {
            font-size: 12px !important;
        }

        .nav-links li a {
            color: #fff !important;
        }

        .mega-links li a {
            color: #0a0a0a !important;
        }

        .drop-menu li a {
            color: #0a0a0a !important;
            font-size: 12px !important;
        }

        .logo img {

            top: 1px !important;
        }

        .about {
            display: flex;
            text-align: center;
            width: 100%;
            align-content: space-between;
            justify-content: space-between;
            padding: 10px 0;
        }

        .buttons {
            .buy {
                width: 83%;
            }

            .wishlist {
                width: 15%;
            }
        }

        //////////////////////////////car-washing-shopping//////////////
        .car-washing-shopping {
            .listmenu {
                display: none;
            }

            .product .border-0 {
                width: 100%;
            }

            .item-card9-footer {
                display: flex;
            }

            .item2-gl-nav {
                width: 100%;
            }

            .item2-gl-nav {

                display: none;

            }

            .item2-gl-group {
                text-align: center;

                .selectgroup {
                    .selectgroup-item {
                        .selectgroup-button {
                            padding: 2px 5px !important;
                            margin: 2px;
                        }

                    }
                }
            }
        }
    }
}

@media screen and (min-width:768px) {
    .filter {
        display: none;
    }
}