.washing_wale-Address {
.font-manrope{
    position: absolute;
    top: 5px;
    left: 29px;
    color: #fff;
}
    position: relative;

    img {
        height: 500px;
        width: 100%;
    }

    .address_fill {
        position: absolute;
        bottom: 84px;
        left: 27px;
    }

    .round_back {
        padding: 10px;
        border-radius: 0.5rem;

        a {
            font-size: 14px;
            display: flex;
            text-decoration: none;
            padding: 5px 0;
            h5{
                padding: 0 10px;
            }
        }
    }

    .text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}