.washing-section-title1 {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    a{
        padding: 2px 10px;
        border: 2px dotted #ed8600 !important;
        border-radius: 20px;
    }
    span {
        
        h2{
            font-size: 20px;
            color: #ed8600;
        }
        span {
        font-weight: 500;
        font-size: 18px;
        color: #070707;
        display: inline-block;
        margin-bottom: 5px;

       
    }}
}