.car-washing-discount{
    background-size: cover;
    background-repeat: no-repeat;
    padding: 5px 0;
   .product-img{
    img{
        width: 100%;
    }
   }
    .offer-tag {
        position: relative;
        height: 194px;
        width: 194px;
        display: flex;
        align-items: center;
        margin: auto 0;
    .offer-content{
        height: 145px;
        width: 145px;
        border-radius: 50%;
        border: 2px solid #3a3a43;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -55%);
      
     span {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 25px;
        font-weight: 700;
        color: #fff;
    }
    p {
        margin-bottom: 0;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
    }
}
}
}