.car-washing-checklist {
  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style-type: none
    }
  }
}
.listing-img img {
  height: 120px ;
}
.Filter {
  width: 100%;
  text-align: center;
  padding: 8px;
  border: 1px solid #ddd;
  margin: 5px 0;
  background: #ed8600;

 

  a {
    font-size: 14px;
    text-decoration: none;
    color: #000000;
    font-weight: 900;
  }
}

.product {
  box-shadow: 0 0 4px 1px rgb(0 0 0 / 38%);
  border-radius: 10px;

  .card {
      border-radius: 2px;
      border: none;
      border-radius: 10px;

      .card-body {
          padding: 0;
          margin: 0;
      }

      .card-footer {
          background: none;
      }
  }

  .washingwale-listing {
      height: 160px;
      width: 160px;
  }

  .item-card9-img {
      width: 35%;
      position: relative;
  }

  .listing-img {
      width: 100%;
      height: 100%;

      img {
          width: 100%;
          height: 100%;
      }
  }
.Driver{
  padding: 0 10px;
  color: #0bec16;
}
  .item-card9-cost {
      p {
          font-size: 20px;
          font-weight: 700;

          span {
              font-size: 16px;
              font-weight: 400;
              margin: 0 10px;
              text-decoration: line-through;
          }
      }
  }

  .item-card9-footer {
      button {
          margin: 0 5px 0 0;
          padding: 0 5px;
      }
  }

  .item-card9-desc {
      p {
          margin: 0;
          padding: 0;
      }
  }
}


.item2-gl-nav{
  padding: 10px;
  margin-bottom: 12px;
  background: #ed8600;

  h6{
    margin: 0;
  }
}
.selectgroup-button {
  display: block;
  border: 1px solid #d8dde6;
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #a7b4c9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  min-width: 2.375rem;
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-dark {
  color: #162946 !important;
  text-decoration: none;
}

.custom-control-label {
  margin-bottom: 0;
  position: static !important;
  vertical-align: middle;
}

.float-end {
  float: right !important;
}

.label-secondary {
  background-color: #dfdfdf;
  color: #080e1b;
}

.item-card9-img {
  width: 50%;
  position: relative;

}

.product .border-0 {
  width: 67%;
}

.item-card9-icons a {
  width: 2rem;
  display: inline-block;
  height: 2rem;
  text-align: center;
  border-radius: 100px;
  line-height: 2rem;
  border-radius: 50px;
  color: #fff;
}

.wishlist {
  background: #080e1b !important;
}

.item-card9-icons {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 98;
}

.car-washing-shopping {

  padding: 5rem 0 1rem;

  .product {
    .card-footer {
      background: #ffffff;
    }
  }
}

#sidebar .card {


  background: #dfdfdf;
}



.product-sidebar {
  border-radius: 10px;
  border: 1px solid rgba(238, 238, 238, .93);
  background: #fff;
  padding: 10px;
}

.product-sidebar .product-widget {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 5px;
  margin: 20px 10px;
}

.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner {
  margin-bottom: 25px;
}

.product-sidebar .product-widget .check-box-item .checkbox-container>ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.product-sidebar .product-widget .check-box-item .checkbox-container>ul>li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.product-sidebar .product-widget .check-box-item .checkbox-container>ul>li .containerss {
  display: flex;
  gap: 5px;
  width: 100%;
  position: relative;
padding-left: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}



.product-sidebar .product-widget .check-box-item .checkbox-container>ul>li .containerss .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgba(255, 255, 255, .8);
  border: 1px solid #383838;
  border-radius: 2px;
}

.product-sidebar .product-widget .check-box-item .checkbox-container>ul>li .containerss span.text {
  font-weight: 600;
  padding-left: 5px;
}

.product-sidebar .product-widget .check-box-item .checkbox-container>ul>li .containerss span {

  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

.product-sidebar .product-widget .check-box-item .checkbox-container .form-inner input {
  width: 100%;
  border-radius: 5px;
  background: #fff;

  font-size: 13px;
  font-weight: 400;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #424141;
}