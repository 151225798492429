.washingwala-logo {
  font-size: 30px;
  font-family: math;
  font-weight: 900;
  color: #fec234;
  border-bottom: 2px dotted;
}

.car-washing-wale-navbar {
  box-shadow: rgba(36, 39, 44, 0.281) 0px 1px 3px;
  margin-bottom: 4px;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0px;

  .bg-body-tertiary {
    background: #ffffff !important;
    padding: 0px;
  }

  .Account .icon {
    margin: 0px;
    padding: 0px;

    .MuiAvatar-circular {
      width: 31px;
      height: 31px;
    }
  }



  .dropdown-manu {
    position: relative;
  }

  .mobile-menu-wahingwale {
    position: absolute;
    z-index: 999;
    right: 93px;
    top: 15px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    top: 40px;
  }

  .img-box {
    position: relative;
    width: 38px;
    height: 38px;
    border-radius: 50%;


  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .dropdown-content::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 14px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    z-index: -1;
  }

  .dropdown-item-user {
    text-align: left;
    padding: 10px !important;

    svg {
      margin: 0 10px;
    }
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    padding: 12px 16px;
  }

  .dropdown-manu:hover .dropdown-content {
    display: block;
    ul{
      margin: 0px;
      hr{
        margin: 1px;
      }
      .dropdown-item-user{
margin: 0px;
      }
    }
  }

  .mobile-menu-wahingwale:hover .dropdown-content {
    display: block;
  }
}

.navbar-washingwale {
  margin-left: 50px;

  a {

    text-decoration: none;
    line-height: 18px;
    border-right: none;
    text-align: left;
    padding: 6px 0px;
    background: #fff;
    background-image: none;
    color: #6c6a8d;
    border-right: 0 none;
    display: block;
    background-color: #fff;
    font-size: 14px;

    &:hover {
      color: #fec234;
    }
  }


}

.top-bar {
  border-bottom: 1px solid #d8dde6;
  background: #dfdfdf;
}

.logo img {
  width: 100px;
  position: relative;
  top: -1px;

}

.top-bar-left ul {
  margin-bottom: 0;
}

.socials {
  display: flex;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.top-bar .top-bar-left .socials li {
  display: inline-block;
  font-size: 15px;
  margin: 4px 15px;
}

.washing-nav {
  position: absolute;
  background: #fff;
  padding: 0;
}

.scrolled {
  .washing-nav {
    box-shadow: rgb(112 112 112) 0px 1px 4px 3px;
    transition: background-color 2s;
  }

}

.top-bar-right .custom li {
  margin-right: 15px;
}

.top-bar-right .custom {
  display: flex;
  margin: 3px 0;
  float: right;
  padding: 0px 35px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.top-bar-right .custom li:last-child {
  margin-right: 0;
}

.top-bar-right .custom li {
  margin-right: 15px;
}

.dropdown {
  display: block;
}

.horizontalMenu .horizontalMenu-list {
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  display: block;
  padding: 0;
}

.horizontalMenu .horizontalMenu-list,
.horizontal-header {
  background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
  text-align: center;
  display: block;
  padding: 0;
  margin: 0;
  float: left;
  padding: 0;
}

.horizontalMenu .horizontalMenu-list li a {
  display: block;
  padding: 9px 15px;
  text-decoration: none;
  position: relative;
  margin: 0 2px;
  border-radius: 3px;
  text-decoration: none !important;
}

.horizontalMenu .horizontalMenu-list li .horizontal-megamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 42px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  font-size: 15px;
  overflow: auto !important;
}

.horizontalMenu .horizontalMenu-list li .horizontal-megamenu .megamenu-content {
  border: 1px solid #e8ebf3;
  background-color: #fff;
  padding: 14px 20px;
  border-radius: 2px;
  box-shadow: 0px 5px 25px rgba(16, 49, 85, 0.2);

}

.horizontalMenu .horizontalMenu-list li .horizontal-megamenu .link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-family: "Poppins", sans-serif !important;
}

.horizontal-megamenu {
  display: none;
}

.horizontalMenu .horizontalMenu-list li {
  &:hover {
    .horizontal-megamenu {
      display: block;
      height: 500px;
      overflow-y: scroll;
    }
  }
}

@media (min-width: 1024px) {
  .horizontalMenu .horizontalMenu-list li .horizontal-megamenu .link-list {
    border-left: 1px solid #e8ebf3;
    padding: 0px;
  }
}

.top-menu .nav-links li:hover .drop-menu {
  top: 52px !important;
}

.link-list .title {
  border-bottom: 1px solid #e8ebf3;
  font-size: 14px;
  color: #fec234;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    justify-content: space-between !important;
  }
}

.washing-nav .navbar-nav {
  a {
    margin: 0 20px;
    color: #000;
    font-weight: 500;

    :hover {
      color: #fec234;
    }
  }

  .nav-item {
    :hover {
      color: #fec234;
    }

    margin: 0 10px;

    a {
      margin: 0;
    }
  }
}