.main2{
    width: 100%;
  background: #fff;
padding-top: 5rem;
padding-bottom: 20px;
box-shadow: rgb(229 225 225) 0px 1px 7px 0px;
    // .firstimg {
    //     width: 50%;
    // }

}
.title-slider{
margin-top: 30px;
padding: 60px 0;
  h1{
    font-size: 50px;
  }
  p{
    font-size: 20px;
  }
  a{
    padding: 10px 50px;
    text-decoration: none;
    background: #000000;
    margin-top: 30px;
    color: #fff;
    border-radius: 10px;
  }
}
.car-filter-area {
  padding: 10px 40px;
  border-radius: 5px;
  background: rgb(254, 147, 7);
  box-shadow: rgb(211 211 211) 0px 1px 10px 4px;
  margin: 0 20px;
}
.select {
  margin-bottom: 25px;
}
.nice-select {
  width: 100%;
  background: #f3f3f3;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0 9px;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #f3f3f3;

}
.form-inner .primary-btn1 {
  width: 100%;
  justify-content: center;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  display: inline-flex;
      align-items: center;
      gap: 10px;
      transition: .5s;
      position: relative;
      overflow: hidden;
      z-index: 1;
      white-space: nowrap;
   background: #222222;
   color: #f3f3f3;
      border: none;
      position: relative;
}
// .main{
//   width: 35%;
//   }
  .thumb {
    width: 50px !important;
    border-radius: 28px;
    margin: -1px;
    border: 1px solid #ddd !important;
    padding: 1px !important;
  }
  .control-arrow {
    display: none !important;
  }
  .carousel-status{
    display: none !important;
  }
  
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
  
  
  }
  .explore-feature{
    box-shadow: rgba(0, 0, 0, 0.3882352941) 1px 2px 4px 0px;
    border: none;

  }
  // .slide2{
  //   width: 30%;
  //    img{
 
  //   border-radius: 60px;
  //   width: 100%;
  // }}
  .slide img{
    height: 280px;
    width: 100%;
  }
  // .main2{
  //   display: flex;
  //   padding: 10rem 0 0;
  // }
  // .heading{
  //   width: 40%;
  //   text-align: center;
  // }

