.car-washing-wale-list{
    padding: 0.5rem 0 2rem;
.icon{
   
    img{
        width: 40px;
    }
}
}
.car-wash-bg-color{
    background: #ffffff !important;
}