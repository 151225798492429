.washing-car {
    padding: 4rem 0 0;

    .car-details-area .car-details-menu.sticky {
        position: fixed;
        top: 60px;
        z-index: 888;
        max-width: 1300px;
        width: 100%;
    }

    .car-washing-wale-rating .star-svg {
        width: 20px;
        height: 20px;
    }

    .checkbox.style-g {
        display: flex;
        position: relative;
        padding-right: 40px;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        align-content: space-around;
        justify-content: space-evenly;
        align-items: center;
    }

    .checkbox.style-g input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox.style-g input:checked~.checkbox__checkmark {
        background-color: #fff;
    }

    .checkbox.style-g input:checked~.checkbox__checkmark:after {
        opacity: 1;
        left: 6px;
    }

    .checkbox.style-g input:checked~.checkbox__body {
        font-weight: bold;
    }

    .checkbox.style-g:hover input~.checkbox__checkmark {
        background-color: #eee;
    }

    .checkbox.style-g:hover input:checked~.checkbox__checkmark {
        background-color: #eee;
    }

    .checkbox.style-g .checkbox__checkmark {
        position: absolute;
        top: 1px;
        right: 0;
        height: 22px;
        width: 22px;
        background-color: #ffffff;
        transition: background-color 0.25s ease;
        border-radius: 4px;
        border: 1px solid #ddd;
    }

    .view_button {
        background: none;
        padding: 7px 15px;
        margin: 0px;
        position: absolute;
        top: 0px;
        border: none;
        left: 0px
    }

    .ClientReview_Card {
        font-weight: 700;
        overflow: hidden;
        padding: 10px 10px 0px;
        position: relative;
        background: #fff;
        border-radius: 5px;
    }


  

    .ClientReview_Stars {
        font-size: 20px;
        padding-left: 0px;
        color: #fe9307;
    }

    .ClientReview_Body {
        padding-bottom: 10px;
        font-size: 12px;
    }

    .ClientReview_Name {
        color: #13588F;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .product {
        box-shadow: 0 0 4px 1px rgb(0 0 0 / 38%);
        border-radius: 10px;

        .card {
            border-radius: 2px;
            border: none;
            border-radius: 10px;

            .card-body {
                padding: 0;
                margin: 0;
            }

            .card-footer {
                background: none;
            }
        }

        .washingwale-listing {
            height: 140px;
            width: 145px;
        }

        .item-card9-img {
            width: 35%;
            position: relative;
        }

        .listing-img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .item-card9-cost {
            p {
                font-size: 20px;
                font-weight: 700;

                span {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0 10px;
                    text-decoration: line-through;
                }
            }
        }

        .item-card9-footer {
            button {
                margin: 0 5px 0 0;
                padding: 0 5px;
            }
        }

        .item-card9-desc {
            p {
                margin: 0;
                padding: 0;
            }
        }
    }

    .checkbox.style-g .checkbox__checkmark:after {
        content: "";
        position: absolute;
        left: -20px;
        top: -2px;
        width: 9px;
        height: 18px;
        border: solid #fe9307;
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.25s ease, left 0.25s ease;
    }

    .checkbox.style-g .checkbox__body {
        color: #333;
        line-height: 1.4;
        font-size: 16px;
        transition: font-weight 0.25s ease;
    }

    .car-details-area .car-details-menu .navbar {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        background-color: #fff;
        padding: 20px 0;
    }

    .car-details-area .car-details-menu .navbar .nav-pills {
        gap: 15px;
        justify-content: space-between;
        width: 100%;
    }

    .car-details-area .car-details-menu .navbar .nav-pills .nav-item {
        line-height: 1;
    }

    .car-details-area .car-details-menu .navbar .nav-pills .nav-item a {
        color: #5e5e5e;
        font-size: 14px;
        font-weight: 600;
        padding: 0;
        line-height: 1;
        background-color: transparent;
    }

    .car-details-area .single-item {
        scroll-margin-top: 200px;
        padding: 10px 0;
    }

    .car-details-area .car-img-area .nav-tabs {
        border-bottom: none;
        justify-content: space-between;
        padding: 22px 0px;
    }

    .car-details-area .car-img-area .nav-tabs .nav-item .nav-link {
        padding: 0;
        border: none;
        color: #5e5e5e;
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 12px;
        transition: .35s;
    }

    .car-details-area .title {
        line-height: 1;
    }

    .car-details-area .title h5 {


        font-size: 20px;
        font-weight: 600;
        position: relative;
        display: inline-block;
        margin-bottom: 0;
    }

    .car-details-area .car-info ul {
        margin: 0;
        padding: 0;
        list-style: none;
        -moz-columns: 4;
        columns: 4;
    }

    .car-details-area .title {
        line-height: 1;
    }

    .car-details-area .kye-features ul {
        margin: 0;
        padding: 0;
        list-style: none;
        -moz-columns: 3;
        columns: 3;
    }

    .car-details-area .kye-features ul li {
        color: #5e5e5e;
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
    }

    .car-details-area .kye-features ul li svg {
        color: #5e5e5e;
        min-width: 12px;
        margin-top: 2px;
    }

    .car-details-area .overview-content {
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid #eee;
        padding: 30px 20px;
    }

    .car-details-area .overview-content ul {
        margin: 0;
        padding: 0;
        list-style: none;
        max-width: 47%;
        width: 100%;
    }

    .car-details-area .overview-content ul li:first-child {
        padding-top: 0;
    }

    .car-details-area .overview-content ul li {
        color: #5e5e5e;
        font-size: 15px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        line-height: 1;
        position: relative;
        padding: 18px 0 10px;
    }

    .car-details-area .overview-content ul li span {
        line-height: 1;
        font-weight: 600;
    }

    .banner-content {
        max-width: 705px;
        width: 100%;
    }

    .banner-content h5 {

        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0px;
    }

    .Service-features {
        display: flex;
        justify-content: space-between;
        margin: 0px;

        li {
            padding: 0 5px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .recent-car-area .product {
        margin: 10px 0px !important;
        .item-card9{
            h5{
                font-size: 16px;
                padding: 0;
                margin: 0;
            }
            p{
                font-size: 12px;
                margin: 0;
                padding: 0;
            }
        }

    }

    .price-model-and-fav-area .price-and-model {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .location-and-notification ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        gap: 40px;
    }

    .car-washing-view {
        .accordion-body {
            padding: 2px 5px;
            font-size: 16px;
        }
    }

    .Summary {
        background: rgb(255 255 255);
        border-radius: 5px;
        box-shadow: rgba(36, 39, 44, 0.281) 0px 1px 3px;


    }

    .cart-body ul .single-item .quantity-area .quantity {
        display: flex;
        gap: 5px;
    }

    .cart-body ul .single-item .quantity-area .quantity input {
        height: 24px;
        width: 34px;
        border-radius: 4px;
        background: #f3f3f3;
        border: 1px solid rgba(238, 238, 238, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }

    .cart-body ul .single-item .quantity-area .quantity a {
        height: 24px;
        width: 34px;
        border-radius: 4px;
        background: #f3f3f3;
        border: 1px solid rgba(238, 238, 238, .1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        transition: .35s;
    }

    .inquiry-form .title {
        margin-bottom: 25px;
    }

    .cart-menu {
        min-height: 10rem;
        z-index: 99;
        transform-origin: top;
    }

    .cart-body ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .cart-body ul .single-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .cart-body ul .single-item .item-area {
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;
        width: 100%;
    }

    .cart-body ul .single-item .item-area .main-item {
        display: flex;
        gap: 15px;
        width: 100%;
    }

    .cart-body ul .single-item .item-area .main-item .content-and-quantity {
        width: 100%;
    }

    .cart-footer {
        justify-content: flex-start;
        padding: 0;
        border-top: 1px solid rgba(255, 255, 255, .7);
    }

    .cart-footer .pricing-area ul {
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid rgba(255, 255, 255, .7);
        width: 100%;
        padding: 10px 0;
    }

    .cart-footer .pricing-area ul li {

        font-weight: 600;
        font-size: 13px;

        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .checkout-page .cart-footer .pricing-area {
        width: 100%;
    }

    .comment li {
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
    }

    .banner-main-content-wrap {
        padding: 20px 15px;
    }

    .car-details-sidebar .contact-info {
        border-radius: 10px;
        padding: 0px 5px;
    }

    ul .more-washing-featuar {
        display: flex;
        justify-content: space-evenly;
        padding: 5px 0;

        button {
            font-size: 12px;
            background: #fe9307;
            border: none;
        }

    }

    .car-wash-wale-addtion-features {
        border: 1px solid #eee;
        padding: 10px 3px;
        background: #fe9307;
        border-radius: 5px;

    }

    .car-details-sidebar .contact-info .single-contact a {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        background: #13141a;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 10px 20px;
        text-align: center;
        width: 100%;
        cursor: pointer;
    }

    .car-details-sidebar .inquiry-form {


        padding: 5px 4px 5px;
    }

    .car-details-sidebar .inquiry-form .title {
        margin-bottom: 2px;
    }

    .car-wash-wale-features ul {
        margin: 0px;
        padding: 0px;
    }

    .car-details-sidebar .inquiry-form .form-inner {
        position: relative;
        line-height: 1;
    }

    .car-details-sidebar .inquiry-form .form-inner input {
        border-radius: 5px;
        font-size: 13px;
        font-weight: 400;
        height: 48px;
        width: 100%;
        padding: 10px 20px;
    }

    .checkboxes__item {
        width: 100%;
    }

    .accordion-button {
        padding: 5px !important;
    }

    .accordion-button::after {
        position: absolute !important;
    }

    .user-qustion-area .comment {
        margin: 0;
        padding: 0;
    }

    .car-details-sidebar .inquiry-form .form-inner label {
        color: #1a1a1a;
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin-bottom: 10px;
    }

    .car-details-sidebar .inquiry-form .form-inner textarea {
        border-radius: 5px;

        font-size: 13px;
        height: 48px;
        width: 100%;
        padding: 10px 20px;
        outline: none;
        border: none;
        min-height: 100px;
    }

    .car-details-sidebar .inquiry-form .form-inner {
        position: relative;
        line-height: 1;

        button {
            background: #fe9307;
            border: none;
        }
    }

    .car-details-sidebar .inquiry-form .form-inner .primary-btn3 {
        padding: 17px 28px;
        width: 100%;
        justify-content: center;
    }

    .product-st-card1.two {
        gap: 10px;
    }

    .product-st-card1 {
        border-radius: 10px;
        border: 1px solid rgba(238, 238, 238, .93);
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 30px;
        transition: .35s;
    }

    @media (max-width: 1399px) {
        .product-st-card1.two .product-img {
            max-width: 150px;
        }
    }

    .product-st-card1.two .product-img {
        position: relative;
        border-radius: 5px 0 0 5px;
        transition: .35s;
        max-width: 180px;
        width: 100%;
    }


    .product-st-card1:hover {
        border-color: #ffa707;
    }

    .product-st-card1.two {
        gap: 10px;
        margin: 23px 0;

    }

    .product-st-card1 {
        border-radius: 10px;
        border: 1px solid rgba(190, 187, 187, 0.93);
        background: #fff;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 30px;
        transition: .35s;
    }

    .product-st-card1.two .product-content h6 {
        margin-bottom: 9px;
        line-height: 1;
    }

    .product-st-card1.two .product-content h6 a {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        transition: .35s;
    }

    @media (max-width: 1399px) {
        .product-st-card1.two .product-content .features {
            gap: 4px;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 1699px) {
        .product-st-card1.two .product-content .features {
            gap: 4px;
        }
    }

    .product-st-card1.two .product-content .features {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;
        position: relative;
        padding-top: 12px;
        padding-bottom: 0;
    }
}

.car-details-area .car-img-area .tab-content .product-img2 .content {
    padding-top: 20px;
}

.car-details-area .single-item {
    scroll-margin-top: 200px;
}

.product-st-card1.two .product-content .features li {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
}