
.car-washing-ragister .form-group input {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
    line-height: 40px;
    margin: 10px 0;
    background-color: white;
    border: 1px solid #5e5d5d;
    border-radius: 5px;
    outline: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
}
.RegisterSeller em{
    color: #ff0202;
    font-size: 14px;
    position: absolute;
    left: 17px;
    margin-top: 48px;
}
.btn-ragister{
    display: flex;
    justify-content: space-around;
    button{
        padding: 5px 30px;
    }
}
.GSTNo{
    display: flex;
    .form-check{
        margin: 0 40px 0;
    }
}
.lead {
    font-size: 16px !important;
    font-weight: 400 !important;
}
.RegisterSeller-popup{
    .modal-content{
        background: #eee !important;
    }
}
.washing-wale-seller {
    

    .pt-165 {
        padding: 105px;
    }

    .new-form {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 30px;
        margin-bottom: 40px;
        border-radius: 20px;
        background-color: #fff;
    }

    .btn-gradient {
        line-height: 50px;
        border-radius: 100px;
        border: 0px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background: linear-gradient(89.75deg, #fe9307 0.15%, #fe9307 99.72%);
        display: inline-block;
        padding: 0 40px;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
.butn-rag-login{
    display: flex;
}
    .hero-slider-content-seller {
        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(254, 147, 7) 100%);

        padding: 60px 30px;
        border-radius: 10px;

    }

    .single-counter {
        margin: 14px 0;
        border: 1px dashed;
        min-width: 175px;
    }

    .gray-light-bg {
        background: #f6f6f6;
    }

    .single-work-process {
        position: relative;
        margin: 0 15px 60px;
        width: calc(25% - 30px);
    }

    .work-process-wrap {
        margin: 20px -15px -60px;
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        -ms-align-items: flex-start;
        align-items: flex-start;
    }

    .work-process-icon-wrap {
        position: relative;
        display: inline-block;
        width: 70px;
        height: 70px;
        padding: 10px;

        span {
            font-size: 26px;
        }

    }

    .quick-link-area {
        background-color: #080808;
        padding: 30px 11%;
    }

    .new-form .heading-1 {
        color: #fe9307;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
    }

    .quick-link-area ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .quick-link-area ul li {
        line-height: 1;
        position: relative;
    }

    .primary-btn1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        justify-content: center;
        padding: 13px 20px;
        width: 100px;
        justify-content: center;
        margin: 0 20px 0 0;
        background-color: #111111;
        border: none;
    }

    .quick-link-area ul li a {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: none;
    }

    .quick-link-area ul li a svg {
        fill: #fff;
    }
}