.car-washing-register {
    padding: 4rem 0;

    .user_details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 5px 10px;
    }
    .login-header {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fe9307;
        width: 140px;
        height: 70px;
        border-radius: 0 0 20px 20px;
    }
    .login-header::before {
        content: "";
        position: absolute;
        top: 0;
        left: -30px;
        width: 30px;
        height: 30px;
        border-top-right-radius: 50%;
        background: transparent;
        box-shadow: 15px 0 0 0 #fe9307;
    }
    .SignUp-box {
        position: relative;
        border-radius: 15px;
        padding: 5em 2em 0em 2em;
        background-color: #ffffff;
    }
    .login-header::after {
        content: "";
        position: absolute;
        top: 0;
        right: -30px;
        width: 30px;
        height: 30px;
        border-top-left-radius: 50%;
        background: transparent;
        box-shadow: -15px 0 0 0 #fe9307;
    }
    .content {
        width: 100%;
        padding: 9em 4em;
        text-align: center;
        background: #07070779;
        svg{
            color: #ffffff;
        }
        h1 {
            font-weight: 700;
            font-size: 3.5em;
            text-align: center;
            color: #ffffff;
        }
        p{
            color: #ffffff;
        }
    }
    .user_details .input_box {
        width: calc(100% / 2 - 20px);
        margin: 0 0 12px 0;
    }

    .input_box label {
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
    }

    .input_box label::after {
        content: " *";
        color: red;
    }

    .input_box input {
        width: 100%;
        height: 35px;
        border: none;
        outline: none;
        border-radius: 5px;
        font-size: 16px;
        padding-left: 15px;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
        background-color: #f6f8fa;
        font-family: 'Poppins', sans-serif;
        transition: all 120ms ease-out 0s;
    }

   .SignUp-box span {
        font-size: 25px;
        color: #100e0e;
    }
    .gender .category span{
        font-size: 15px;
        color: #100e0e;
    }
    .input_box input:focus,
    .input_box input:valid {
        box-shadow: 0px 0px 0px 2px #AC8ECE;
    }

    form .gender {
        padding: 0px 10px;
    }

    .gender .gender_title {
        font-size: 16px;
        font-weight: 500;
    }

    .gender .category {
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
    }

    .gender .category label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .gender .category label .dot {
        height: 18px;
        width: 18px;
        background: #d9d9d9;
        border-radius: 50%;
        margin-right: 10px;
        border: 4px solid transparent;
        transition: all 0.3s ease;
    }

    #radio_1:checked~.category label .one,
    #radio_2:checked~.category label .two,
    #radio_3:checked~.category label .three {
        border-color: #d9d9d9;
        background: #D64141;
    }

    .gender input {
        display: none;
    }

    .reg_btn {
        padding: 25px;
        margin: 15px 0;
    }

    .reg_btn input {
        height: 45px;
        width: 100%;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        background: #fe9307;
        border-radius: 5px;
        color: #ffffff;
        letter-spacing: 1px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .reg_btn input:hover {
        background: #fe9307;
    }

    @media screen and (max-width: 584px) {

        .user_details {
            max-height: 340px;
            overflow-y: scroll;
        }

        .user_details::-webkit-scrollbar {
            width: 0;
        }

        .user_details .input_box {
            width: 100%;
        }

        .gender .category {
            width: 100%;
        }

    }


    @media screen and (max-width: 419px) {
        .gender .category {
            flex-direction: column;
        }
    }
}