.privacy{
    h2{
        font-size: 16px;
    }
    p{
        font-size: 12px;
    }
    ul{
        li{
            font-size: 12px;
        }
    }
}