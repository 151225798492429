

.App {
  text-align: center;
}


.slider img{
  width: 100%;
}
.d-inline-block {
  display: inline-block !important;
  text-decoration: none;
}

.swiper-button-next {
  background: #31302e78;
  border-radius: 50% !important;
  padding: 10px !important;
  position: absolute;
  height: 30px !important;
  width: 30px !important;
  right: 0px;
  z-index: 999;
  box-shadow: rgba(36, 39, 44, 0.281) 0px 1px 3px !important;
}
.swiper-button-next:after {
      font-size: 16px !important;
      font-weight: 700;
      color: #ffffff;
  }


.swiper-button-prev {
  background: #31302e78;
  border-radius: 50% !important;
  padding: 10px !important;
  position: absolute;
  height: 30px !important;
  width: 30px !important;
  left:0px;
  z-index: 999;
  box-shadow: rgba(36, 39, 44, 0.281) 0px 1px 3px !important;
}

.swiper-button-prev:after {
      font-size: 16px !important;
      font-weight: 700;
      color: #ffffff;
  }
  .Filter{
    display: none;
  }
  .bottom-navbar{
    display: none !important;
}
.booking-model .modal-dialog {
  width: 30% !important;
}