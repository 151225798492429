.hero-equal-height {
    position: relative;
    min-height: 300px;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.hero-slider-content{
    color: #fff;
}
 .testing-gray {
    height: 250px;
}
.text-back{
    background: #ffffff;
    padding: 2px 30px;
    border-radius: 30px;
    .hero-slider-content{
        color:#000000;
        font-weight: 400;
        h2{
            font-weight: 400;
        }
        p{
            font-size: 14px;
            font-weight: 400;
            text-align: justify;
        }
       
       
    }
}
 .car-washing {
    background: #032e48;
    border-radius: 10px 10px;
    margin: 10px 0;
    padding: 30px 20px 20px;
    text-align: center;
    transition: .4s;
    color: #fff;
    p{
        text-align: justify;
    }
}
 .bg-blue-gr {
    background-image: linear-gradient(to bottom right, #e98222, #e98222);
    padding: 30px 0;
}
.car-washing p span {
    color: #e98222;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    transition: .4s;
}