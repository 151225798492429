.washing-wale-footer {
  margin-top: 50px;
  background: rgb(0, 0, 0);


  .footer-widget .widget-title h5 {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }

  .footer-top {
    padding: 10px;
  }

  .copyright-area {
    color: #b3b3b3;
    font-size: 16px;

    a {
      text-decoration: none;
      color: #b3b3b3;
    }
  }

  .footer-widget .menu-container ul li a {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 600;
    transition: .35s;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .footer-widget .menu-container ul li {
    margin-bottom: 8px;
  }

  .footer-widget .menu-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .footer-widget .widget-title {
    margin-bottom: 13px;
  }

  .footer-widget .menu-container ul li a {
    color: #b3b3b3;
    font-size: 12px;
    font-weight: 600;
    transition: .35s;
    display: flex;
    align-items: center;
    gap: 7px;
    text-decoration: none;
  }

  .footer-btm .social-area {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .footer-btm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, .05);

  }

  .footer-btm .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px;
  }
}