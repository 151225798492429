.carlisting-washingwale {
    padding: 10px 0;
    .washingwale-loction{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding:0px 2px;
    }
    .swiper-slide:hover {
        .washingwale-listing{
            box-shadow: #ff5e14 0px 1px 3px;
            
        }
    }
    .listing-features{
        
        span{
            
            font-size: 12px !important;
            font-weight: 500;
            margin: 0px;   
           
        }
    }
    .washingwala-car-rating{
        vertical-align: inherit !important;
    }
    .listing-content {
        padding: 4px;
        text-align: justify;
        box-shadow: rgba(0, 0, 0, 0.3882352941) 1px 2px 4px 0px;
        border-radius: inherit;

        p {
            font-size: 14px;
            font-weight: 700;
            margin: 0px;
        }
    }

    .carwashing-noida-servies {
        padding: 2px 20px;
        font-size: 12px;
        text-align: left;
        li{
            list-style-type: disclosure-closed;
        }
    }

    .swiper-button-lock {
        display: inline;
    }

    .washingwale-heading {
        font-size: 20px;
        font-weight: 700;
        margin: 5px 0px;


    }

    .noida-carwashing-title {
        border-bottom: 2px solid #ed8600;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        a {
            margin: 2px 0px;
        }
    }

    .mySwiper {
        .washingwale-listing {
            .listing-img {
                img {
                    width: 100%;
                    border-radius: 10px 10px 0 0;
                }

            }

            position: relative;
            background: #ffffff !important;
            border-radius: 10px;
            margin: 0 0 24px;
            box-shadow: rgba(36, 39, 44, 0.15) 0px 1px 3px;

            .washingwala-car-rating {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            .listing-title {
                font-size: 14px;
                margin-bottom: 0px;
                display: flex;
                align-content: center;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
            }

            .btn-order {
            color:  #ed8600 !important;
                position: relative;
                border-radius: 5px;
                font-weight: 600;
                font-size: 14px;
                color: #0e0d0d;
                text-align: center;
                padding: 2px 0;
                border-radius: 10px;

            }

            .fav-item {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                padding: 4px 0px;
                z-index: 1;
                font-size: 14px;

                .featured-text {
                    background: #09780e;
                    border-radius: 0px 10rem 0em 0px;
                    min-width: 75px;
                    padding: 5px 15px;
                    font-size: 14px;
                    color: #ffffff;
                }
            }

            .listing-details-group {
                margin: 0 0 15px;
            }

            .listing-button {
                display: flex;
                padding: 0px;
                justify-content: space-between;
                en{
                    position: absolute;
                    top: 3px;
                    margin: 0 3px;
                    color: #000;
                }
            }
        }
    }
}